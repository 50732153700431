import axios from "axios";
import {useWebApp} from "vue-tg";

const testInitData = "query_id=AAHR4BkhAAAAANHgGSEfrvAQ&user=%7B%22id%22%3A555344081%2C%22first_name%22%3A%22Dmitriy%20Pominov%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22dpominov%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1696343392&hash=c776a77d462c711dce672a17cee183ef0ce41e592aba38ad5559c5ed581b8dda"

const api = {
    async fetchCatalog(bi) {
        try {
            console.log(process.env.VUE_APP_API_URL);
            return axios(`${process.env.VUE_APP_API_URL}m?bi=${bi}`);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchProducts(bi, catId) {
        try {
            console.log(catId)
            return axios(`${process.env.VUE_APP_API_URL}cat/${catId}?bi=${bi}`);
        } catch (error) {
            console.error(error);
        }
    },
    async fetchOrders(bi) {
        try {
            let initData = useWebApp().initData ? useWebApp().initData : testInitData;
            return axios(`${process.env.VUE_APP_API_URL}orders?bi=${bi}&initData=` + encodeURIComponent(initData));
        } catch (error) {
            console.error(error);
        }
    },
    async postOrder(bi, products) {
        let initData = useWebApp().initData ? useWebApp().initData : testInitData;
        return axios.post(`${process.env.VUE_APP_API_URL}order?bi=${bi}`, {
            products: products.map((p) => {
                return {"ID": p.ID, "quantity": p.quantity}
            }), initData
        }).catch(function (error) {
            console.log(error);
        });
    },
}

export default api;
