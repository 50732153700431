<template>
  <div class="app">
    <div v-if="showPreloader">Загрузка</div>

    <AppHeader @closeApp="closeApp"/>

    <router-view></router-view>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import {mapActions, mapState} from "vuex";

export default {
  components: {AppHeader},
  data() {
    return {
      isAppActive: true,
    }
  },
  computed: {
    ...mapState([
      "showPreloader"
    ]),
  },
  methods: {
    closeApp() {
      this.isAppActive = !this.isAppActive;
      this.$store.state.cart = [];
      this.$router.push('/')
    },
    ...mapActions([
      'setBi'
    ]),
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    let bi = urlParams.get('bi');
    if (!bi) {
      bi = 6157754329
      console.log("set test bi");
    }
    console.log("bi=" + bi);
    this.setBi(bi)
    this.$router.push('/')
  }
}
</script>

<style lang="scss">
.app {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  background-color: #1F2937;
  color: #fff;
  border-radius: 7px;
  box-shadow: 0 0 8px 3px rgba(34, 60, 80, 0.2);
}

.open-app {
  width: 400px;
  padding: 10px 0;
  margin: 0 auto;
  background-color: #1F2937;
  color: #fff;
  border-radius: 7px;
  box-shadow: 0 0 8px 3px rgba(34, 60, 80, 0.2);
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #fca405;
  }
}
</style>
