import {createStore} from 'vuex';
import api from '@/services/api';
import {useWebAppClosingConfirmation} from "vue-tg";

export default createStore({
    state: {
        catalog: [],
        products: [],
        cart: [],
        bi: 0,
        orders: [],
        showPreloader: false,
    },
    mutations: {
        setCatalog: (state, catalog) => {
            state.catalog = catalog;
        },
        setProducts: (state, products) => {
            state.products = products;
        },
        setOrders: (state, orders) => {
            state.orders = orders;
        },
        setBi: (state, bi) => {
            state.bi = bi;
        },
        addToCart: (state, product) => {
            product.isBtnActive = false;
            useWebAppClosingConfirmation().enableClosingConfirmation();
            product.quantity = 1;
            state.cart.push(product);
        },
        increaseQuantity: (state, productId) => {
            state.cart.map(product => {
                if (product.ID === productId) {
                    product.quantity++;
                    if (product.quantity > 0) {
                        product.isBtnActive = false;
                        useWebAppClosingConfirmation().enableClosingConfirmation();
                    }
                }
            })
        },
        reduceQuantity: (state, productId) => {
            state.cart.map(product => {
                if (product.ID === productId) {
                    if (product.quantity > 0) {
                        product.quantity--;
                    }
                    if (product.quantity === 0) {
                        product.isBtnActive = true;
                        useWebAppClosingConfirmation().disableClosingConfirmation();
                        state.cart = state.cart.filter(product => product.ID !== productId);
                    }
                }
            })
        },
        showPreloader: (state) => {
            state.showPreloader = true;
        },
        hidePreloader: (state) => {
            state.showPreloader = false;
        },
    },
    actions: {
        async fetchCatalog({commit}) {
            const response = await api.fetchCatalog(this.state.bi);
            commit('setCatalog', response.data);
        },
        async fetchProducts({commit}, catId) {
            const response = await api.fetchProducts(this.state.bi, catId);
            commit('setProducts', response.data);
        },
        async fetchOrders({commit}) {
            const response = await api.fetchOrders(this.state.bi);
            commit('setOrders', response.data);
        },
        setBi({commit}, bi) {
            commit('setBi', bi);
        }
    },
})
