<template>
  <BackButton v-if="routeName !== 'catalog' && routeName !== 'order'" @click="$router.back()" />
</template>

<script>
import {BackButton} from "vue-tg"

export default {
  name: "AppHeader",
  components: {BackButton},
  computed: {
    routeName() {
      return this.$route.name
    },
  },
}
</script>
