<template>
  <h2>Заказы</h2>

  <ul>
    <AppOrdersItem v-for="order in orders" :key="order.ID" :order="order"/>
  </ul>

  <MainButton text="Назад" @click="$router.push('/')"/>
</template>

<script>
import AppOrdersItem from "@/components/AppOrdersItem";
import {mapActions, mapMutations, mapState} from 'vuex';
import {MainButton} from "vue-tg"

export default {
  name: "AppOrders",
  components: {AppOrdersItem, MainButton},
  computed: {
    ...mapState([
      "orders"
    ]),
  },
  methods: {
    ...mapMutations([
      'showPreloader',
      'hidePreloader',
    ]),
    ...mapActions([
      'fetchOrders',
    ]),
  },
  async mounted() {
    this.showPreloader()
    await this.fetchOrders()
    this.hidePreloader()
  }
}
</script>

<style lang="scss" scoped>
.footer {
  &__item {
    padding: 10px 0;
    text-align: center;
  }

  &__title {
    font-size: 14px;
    opacity: 0.4;
  }

  &__order {
    padding: 20px 0;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    background-color: #3cb639;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: #43c541;
    }
  }
}
</style>
