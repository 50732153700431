import { createApp } from 'vue';
import App from './App.vue';
import '@/styles/index.css';
import router from './router/router';
import store from './store/store';
// import VueTelegram from "vue-tg";

const app = createApp(App);

app.config.globalProperties.window = window

app
    .use(store)
    .use(router)
    // .use(VueTelegram)
    .mount('#app');
