<template>
  <li>
    <article>
      <div>
        <span>Заказ {{ order.ID }}</span>
        -
        <strong>{{ order.Status == 'Новый' ? 'В работе' : order.Status }}</strong>
      </div>
    </article>
  </li>
</template>

<script>
export default {
  name: "AppOrdersItem",
  props: {
    order: {
      type: Object,
      required: true
    },
  },
}
</script>
