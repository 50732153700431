<template>
  <div class="cart">
    <div class="cart__wrapper">
      <div class="cart__header">
        <h2 class="cart__title">Корзина</h2>
        <div class="cart__to-catalog" @click="$router.back()">Редактировать</div>
      </div>

      <AppCartItem class="cart__item" v-for="cartItemProduct in cart" :key="cartItemProduct.ID" :cartItemProduct="cartItemProduct"/>
      <div class="cart_total_cost">Итого {{ cartTotalCost }} ₽</div>
    </div>

    <MainButton :text="orderButtonName" :disabled="orderButtonDisable" @click="order"/>
  </div>
</template>

<script>
import AppCartItem from "@/components/AppCartItem";
import {MainButton} from "vue-tg"
import {mapState} from "vuex";
import api from "@/services/api";
import { useWebAppClosingConfirmation } from "vue-tg";

export default {
  name: "AppCart",
  components: {AppCartItem, MainButton},
  data() {
    return {
      orderButtonName: 'Оформить заказ',
      orderButtonDisable: false,
    }
  },
  computed: {
    ...mapState([
      'bi',
      'cart'
    ]),
    cartTotalCost() {
      let result = [];

      if (this.cart.length) {
        for (let product of this.cart) {
          result.push(product.Price.toFixed(2) * product.quantity.toFixed(2));
        }

        result = result.reduce(function (sum, el) {
          return sum + el;
        })

        result.toFixed(2);
      }
      return result
    }
  },
  methods: {
    async order() {
      this.orderButtonName = "Обрабатывается"
      this.orderButtonDisable = true
      await api.postOrder(this.bi, this.cart);
      this.$store.state.cart = [];
      console.log(useWebAppClosingConfirmation().isClosingConfirmationEnabled)
      useWebAppClosingConfirmation().disableClosingConfirmation();
      console.log(useWebAppClosingConfirmation().isClosingConfirmationEnabled)

      this.orderButtonDisable = false;
      this.orderButtonName = "Заказать";
      this.$router.push('/orders')
    }
  }
}
</script>

<style lang="scss" scoped>
.cart {
  position: relative;

  &__wrapper {
    height: 475px;
    overflow-y: scroll;
    background-color: #000;
  }

  &__header {
    padding: 0 20px 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1F2937;
  }

  &__title {
    text-transform: uppercase;
    font-size: 16px;
  }

  &__to-catalog {
    color: green;
  }
}

.cart_total_cost {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}

.form {
  padding: 20px;

  &__input {
    margin-top: 10px;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    font-size: 16px;
    background-color: #1F2937;
    border-color: transparent;
    outline: transparent;
    caret-color: #fff;
    color: #fff;
  }

  &__label {
    color: #797a7a;
  }
}

.footer {
  position: sticky;
  display: block;
  bottom: 0;
  width: 100%;

  &__pay {
    background-color: #3cb639;
    padding: 20px 0;
    width: 100%;
    text-transform: uppercase;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    border-color: transparent;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: #43c541;
    }
  }
}
</style>
