import {createRouter, createWebHashHistory} from "vue-router";
import AppCatalog from "@/components/AppCatalog";
import AppProducts from "@/components/AppProducts";
import AppCart from "@/components/AppCart";
import AppOrdes from "@/components/AppOrdes.vue";

const routes = [
    {
        path: '/',
        component: AppCatalog,
        name: 'catalog',
    },
    {
        path: '/cat/:id',
        component: AppProducts,
        props: true,
    },
    {
        path: '/cart',
        component: AppCart,
        name: 'cart',
    },
    {
        path: '/orders',
        component: AppOrdes,
        name: 'orders',
    },
]

const router = createRouter({
    routes,
    history: createWebHashHistory(process.env.BASE_URL)
})

export default router;
