<template>
  <ul class="catalog">
    <AppProductItem v-for="product in products" :key="product.ID" :product="product"/>
  </ul>

  <MainButton v-if="$store.state.cart.length" text="Заказать" @click="$router.push('/cart')"/>
</template>

<script>
import AppProductItem from "@/components/AppProductItem";
import {mapActions, mapMutations, mapState} from 'vuex';
import {MainButton} from "vue-tg"

export default {
  name: "AppProducts",
  components: {AppProductItem, MainButton},
  props: ['id'],
  computed: {
    ...mapState([
      "products"
    ]),
  },
  methods: {
    ...mapMutations([
      'increaseQuantity',
      'reduceQuantity',
      'showPreloader',
      'hidePreloader',
    ]),
    ...mapActions([
      'fetchProducts'
    ]),
  },
  async mounted() {
    this.showPreloader()
    await this.fetchProducts(this.id)
    this.hidePreloader()
  }
}
</script>

<style lang="scss" scoped>
.catalog {
  padding: 15px;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  height: 475px;
  overflow-y: scroll;
}

.footer {
  &__item {
    padding: 10px 0;
    text-align: center;
  }

  &__title {
    font-size: 14px;
    opacity: 0.4;
  }

  &__order {
    padding: 20px 0;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    background-color: #3cb639;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: #43c541;
    }
  }
}
</style>
